import { AppConfig } from "./config";

export const stagingConfig: AppConfig = {
    apiURL: "https://gs-function-dev.azurewebsites.net/api",
    mixpanel: {
        apiKey: 'e727a5c5bd1303bb11aa158f0610cbe0'
    },
    posthog: {
        apiKey: 'phc_XlxAqAOrEOtFPoYJZ25jK4Pm944vdNpV1FY9RTaWINE',
        apiHost: 'https://rvp-gncrcngydya0g6fz.z01.azurefd.net'
    }
}